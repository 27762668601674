<div
  class="planner-drag-place-holder-shared"
  *cdkDragPlaceholder
></div>

<div
  [style.background]="workContext().theme.primary"
  class="color-bar"
></div>

<button
  #routeBtn
  [routerLink]="[type() === 'TAG' ? 'tag' : 'project', workContext().id, 'tasks']"
  routerLinkActive="isActiveRoute"
  mat-menu-item
>
  <span class="badge">{{ nrOfOpenTasks() }}</span>
  <mat-icon class="drag-handle">{{ workContext().icon || defaultIcon() }}</mat-icon>
  <span class="text">{{ workContext().title }}</span>
</button>

<!--[matMenuTriggerFor]="contextMenuEl"-->

<button
  #settingsBtn
  class="additional-btn"
  mat-icon-button
>
  <mat-icon>more_vert</mat-icon>
</button>

<context-menu
  [contextMenu]="contextMenu"
  [rightClickTriggerEl]="routeBtn"
  [leftClickTriggerEl]="settingsBtn"
></context-menu>

<ng-template #contextMenu>
  <work-context-menu
    [contextId]="workContext().id"
    [contextType]="type()"
  ></work-context-menu
></ng-template>
