<ng-container>
  @if (task()?.issueWasUpdated) {
    <div
      @expand
      style="text-align: center"
    >
      <button
        (click)="hideUpdates()"
        color="accent"
        mat-raised-button
      >
        {{ T.F.OPEN_PROJECT.ISSUE_CONTENT.MARK_AS_CHECKED | translate }}
      </button>
    </div>
  }

  <div class="table-wrapper">
    <table class="issue-table">
      <tr>
        <th>{{ T.F.OPEN_PROJECT.ISSUE_CONTENT.SUMMARY | translate }}</th>
        <td>
          <a
            [href]="issue?.url"
            target="_blank"
            ><strong>{{ issue?.subject }} #{{ issue?.id }}</strong></a
          >
        </td>
      </tr>

      @if (issue?._embedded.type?.name) {
        <tr>
          <th>{{ T.F.OPEN_PROJECT.ISSUE_CONTENT.TYPE | translate }}</th>
          <td>{{ issue?._embedded.type.name }}</td>
        </tr>
      }
      @if (issue?._embedded.status) {
        <tr>
          <th>{{ T.F.OPEN_PROJECT.ISSUE_CONTENT.STATUS | translate }}</th>
          <td>
            <span
              class="dot"
              [style.backgroud-color]="issue?._embedded.status.color"
            ></span
            >{{ issue?._embedded.status.name }}
          </td>
        </tr>
      }
      @if (issue?._embedded.assignee?.name) {
        <tr>
          <th>{{ T.F.OPEN_PROJECT.ISSUE_CONTENT.ASSIGNEE | translate }}</th>
          <td>{{ issue?._embedded.assignee.name }}</td>
        </tr>
      }
      @if (issue?.description?.raw || issue?.description?.html) {
        <tr>
          <th>{{ T.F.OPEN_PROJECT.ISSUE_CONTENT.DESCRIPTION | translate }}</th>
          <td class="issue-description">
            <div
              [data]="issue?.description.raw || issue?.description.html"
              class="description markdown"
              markdown
            ></div>
          </td>
        </tr>
      }
    </table>

    <!--    <div *ngIf="issue?.comments">-->
    <!--      <div-->
    <!--        *ngFor="let comment of (issue?.comments|sort:'created_at'); trackBy: trackByIndex"-->
    <!--        class="comment"-->
    <!--      >-->
    <!--        &lt;!&ndash;<img [src]="comment.author.avatarUrl"&ndash;&gt;-->
    <!--        &lt;!&ndash;class="author-avatar">&ndash;&gt;-->
    <!--        <div class="name-and-comment-content">-->
    <!--          <div>-->
    <!--            <span class="author-name">{{comment.user?.login}}</span>-->
    <!--            <span class="when"-->
    <!--              >{{T.F.OPEN_PROJECT.ISSUE_CONTENT.AT|translate}}-->
    <!--              {{comment.created_at|date:'short'}}</span-->
    <!--            >-->
    <!--          </div>-->
    <!--          <div-->
    <!--            *ngIf="comment.body"-->
    <!--            [innerHTML]="comment?.body|markdown|async"-->
    <!--            class="markdown"-->
    <!--          ></div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--<pre><code>-->
    <!--{{issue?|json}}-->
    <!--</code></pre>-->
  </div>
</ng-container>
