<formly-form
  [form]="form"
  (modelChange)="boardCfg.set($event)"
  [fields]="BOARDS_FORM"
  [model]="boardCfg()"
></formly-form>

@if (!isHideBtns()) {
  <div class="btn-wrapper">
    @if (isEdit()) {
      <button
        (click)="removeBoard()"
        mat-button
      >
        <mat-icon class="color-warn-i">delete_forever</mat-icon>
        <span class="text">{{ T.G.DELETE | translate }}</span>
      </button>
    }

    <button
      [disabled]="!form.valid"
      (click)="save()"
      class="btn btn-primary submit-button"
      color="primary"
      mat-raised-button
      type="submit"
    >
      <mat-icon>save</mat-icon>
      {{ T.G.SAVE | translate }}
    </button>
  </div>
}
