<form
  #searchForm
  @blendInOut
  (@blendInOut.done)="onAnimationEvent($event)"
  class="search-form mat-elevation-z4"
>
  <div class="input-wrapper">
    @if (isLoading$ | async) {
      <div class="spinner">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    }
    <mat-icon class="search-icon">search</mat-icon>
    <input
      #inputEl
      tabindex="0"
      (blur)="onBlur($event)"
      [formControl]="taskSuggestionsCtrl"
      [matAutocomplete]="autoEl"
      [placeholder]="T.F.SEARCH_BAR.PLACEHOLDER | translate"
      matInput
    />

    <button
      (click)="closeBtnClose($event)"
      class="close-btn"
      mat-icon-button
      type="button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="info-bar">
    <div class="info-text">
      {{
        (!tooManyResults ? T.F.SEARCH_BAR.INFO : T.F.SEARCH_BAR.TOO_MANY_RESULTS)
          | translate
      }}
    </div>
  </div>

  <mat-autocomplete
    #autoEl="matAutocomplete"
    (optionSelected)="navigateToItem($event?.option?.value)"
    autoActiveFirstOption
    [displayWith]="getOptionText"
  >
    @for (item of filteredIssueSuggestions$ | async; track trackByFn($index, item)) {
      <mat-option
        (click)="navigateToItem(item)"
        class="search-mat-option"
        [value]="item"
      >
        @if (item.ctx) {
          <tag [tag]="item.ctx"></tag>
        }
        <span>{{ item?.titleHighlighted || item?.title }}</span>
        @if (item.issueType) {
          <mat-icon [svgIcon]="item.issueType | issueIcon"></mat-icon>
        }
        @if (item.isArchiveTask) {
          <mat-icon>archive</mat-icon>
        }
      </mat-option>
    }
  </mat-autocomplete>
</form>
