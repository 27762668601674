<h1
  mat-dialog-title
  align="center"
>
  <span>{{ T.F.PLANNER.D.ADD_PLANNED.TITLE | translate }}</span>
</h1>

@if (day$ | async; as day) {
  <mat-dialog-content>
    <header>
      <!--<div class="day-estimate">{{ day.timeEstimate |roundDuration|msToString }}</div>-->
      <div class="day-counts">
        <span>{{ day.timeEstimate | roundDuration | msToString }}</span>
        @if (day.itemsTotal > 5) {
          <mat-icon inline="true">task_alt</mat-icon>
          <span>{{ day.itemsTotal }}</span>
        }
      </div>
    </header>
    <div class="normal-tasks">
      <div class="normal-tasks-items">
        @for (task of day.tasks; track task.id) {
          <planner-task
            [task]="task"
            [day]="day.dayDate"
          ></planner-task>
        }
        <!-- -->
        @for (
          repeatProjection of day.noStartTimeRepeatProjections;
          track repeatProjection.id
        ) {
          <planner-repeat-projection
            [repeatCfg]="repeatProjection.repeatCfg"
          ></planner-repeat-projection>
        }
        <!-- -->
        @if (day.tasks.length === 0 && day.noStartTimeRepeatProjections.length === 0) {
          <div class="empty">{{ T.F.PLANNER.NO_TASKS | translate }}</div>
        }
      </div>
      <add-task-inline [planForDay]="day.dayDate"></add-task-inline>
    </div>
    <div class="scheduled">
      <div class="section-title">
        <mat-icon>alarm</mat-icon>
      </div>
      <div class="scheduled-items">
        @for (scheduledItem of day.scheduledIItems; track scheduledItem.id) {
          <div
            class="scheduled-item"
            [ngClass]="scheduledItem.type"
          >
            @if (scheduledItem.type === SCHEDULE_ITEM_TYPE.Task) {
              <div
                class="time"
                (click)="editTaskReminderOrReScheduleIfPossible(scheduledItem.task)"
              >
                <span>{{ scheduledItem.start | roundDuration | date: 'HH:mm' }}</span>
                <mat-icon>alarm</mat-icon>
              </div>
            } @else {
              <div class="time">
                {{ scheduledItem.start | roundDuration | date: 'HH:mm' }}
              </div>
            }
            <!-- -->
            @switch (scheduledItem.type) {
              @case (SCHEDULE_ITEM_TYPE.Task) {
                <planner-task
                  [task]="scheduledItem.task"
                  [day]="day.dayDate"
                ></planner-task>
              }
              @case (SCHEDULE_ITEM_TYPE.CalEvent) {
                <planner-calendar-event
                  [calendarEvent]="scheduledItem.calendarEvent"
                ></planner-calendar-event>
              }
              @case (SCHEDULE_ITEM_TYPE.RepeatProjection) {
                <planner-repeat-projection
                  [repeatCfg]="scheduledItem.repeatCfg"
                ></planner-repeat-projection>
              }
            }
          </div>
        }
        <!-- -->
        @if (day.scheduledIItems.length === 0) {
          <div class="empty">
            {{ T.F.PLANNER.PLAN_VIEW.NO_SCHEDULED_ITEMS | translate }}
          </div>
        }
      </div>
    </div>
  </mat-dialog-content>
}

<mat-dialog-actions align="end">
  <button
    (click)="dismiss()"
    color="primary"
    mat-button
    type="button"
  >
    {{ T.G.DISMISS | translate }}
  </button>

  <!--  <button-->
  <!--    color="primary"-->
  <!--    mat-stroked-button-->
  <!--    (click)="rePlanAll()"-->
  <!--  >-->
  <!--    <mat-icon>wb_sunny</mat-icon>-->
  <!--    {{ T.F.PLANNER.D.ADD_PLANNED.RE_PLAN_ALL | translate }}-->
  <!--  </button>-->

  <button
    color="primary"
    mat-stroked-button
    (click)="addTasksToToday()"
  >
    <mat-icon>wb_sunny</mat-icon>
    {{ T.F.PLANNER.D.ADD_PLANNED.ADD_TO_TODAY | translate }}
  </button>
</mat-dialog-actions>
