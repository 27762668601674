<div class="page-wrapper component-wrapper">
  <h1 style="margin-top: 16px; text-align: center">
    {{ T.F.PROCRASTINATION.H1 | translate }}
  </h1>

  <mat-tab-group dynamicHeight>
    <mat-tab [label]="T.F.PROCRASTINATION.INTRO.TITLE | translate">
      <section class="tab-inner">
        <p>{{ T.F.PROCRASTINATION.P1 | translate }}</p>
        <p [innerHTML]="T.F.PROCRASTINATION.P2 | translate"></p>

        <div class="procrastination-graph">
          <div class="wrap">
            <div class="text-item">{{ T.F.PROCRASTINATION.INTRO.FEAR | translate }}</div>
            <div class="text-item">
              {{ T.F.PROCRASTINATION.INTRO.AVOIDING | translate }}
            </div>
          </div>
          <mat-icon>sync</mat-icon>
          <div class="text-item">
            {{ T.F.PROCRASTINATION.INTRO.STRESSED | translate }}
          </div>
        </div>
      </section>
    </mat-tab>

    <mat-tab [label]="T.F.PROCRASTINATION.CUR.TITLE | translate">
      <section class="tab-inner">
        <p>{{ T.F.PROCRASTINATION.CUR.INTRO | translate }}</p>
        <ul>
          <li>{{ T.F.PROCRASTINATION.CUR.L1 | translate }}</li>
          <li>{{ T.F.PROCRASTINATION.CUR.L2 | translate }}</li>
          <li>{{ T.F.PROCRASTINATION.CUR.L3 | translate }}</li>
          <li>{{ T.F.PROCRASTINATION.CUR.L4 | translate }}</li>
          <li>{{ T.F.PROCRASTINATION.CUR.L5 | translate }}</li>
        </ul>
        <h3>{{ T.F.PROCRASTINATION.CUR.PROCRASTINATION_TRIGGERS_TITLE | translate }}</h3>
        <p>{{ T.F.PROCRASTINATION.CUR.PROCRASTINATION_TRIGGERS_TEXT | translate }}</p>

        <!--        <mat-form-field>-->
        <!--          <textarea matInput-->
        <!--                    placeholder="What feelings are eliciting your temptation to procrastinate?"></textarea>-->
        <!--        </mat-form-field>-->
        <!--        <mat-form-field>-->
        <!--          <textarea matInput-->
        <!--                    placeholder="Where do you feel them in your body?"></textarea>-->
        <!--        </mat-form-field>-->
        <!--        <mat-form-field>-->
        <!--          <textarea matInput-->
        <!--                    placeholder="What do they remind you of?"></textarea>-->
        <!--        </mat-form-field>-->
        <!--        <mat-form-field>-->
        <!--          <textarea matInput-->
        <!--                    placeholder="What happens to the thought of procrastinating as you observe it?"></textarea>-->
        <!--          <mat-hint>Does it intensify?-->
        <!--            Dissipate?-->
        <!--            Cause other emotions to arise?-->
        <!--          </mat-hint>-->
        <!--        </mat-form-field>-->
        <!--        <mat-form-field>-->
        <!--          <textarea matInput-->
        <!--                    placeholder="How are the sensations in your body shifting as you continue to rest your awareness on them?"></textarea>-->
        <!--        </mat-form-field>-->
      </section>
    </mat-tab>

    <mat-tab [label]="T.F.PROCRASTINATION.COMP.TITLE | translate">
      <section class="tab-inner">
        <p>{{ T.F.PROCRASTINATION.COMP.INTRO | translate }}</p>
        <ul>
          <li>{{ T.F.PROCRASTINATION.COMP.L1 | translate }}</li>
          <li>{{ T.F.PROCRASTINATION.COMP.L2 | translate }}</li>
          <li>{{ T.F.PROCRASTINATION.COMP.L3 | translate }}</li>
          <li>{{ T.F.PROCRASTINATION.COMP.L4 | translate }}</li>
        </ul>

        <p [innerHTML]="T.F.PROCRASTINATION.COMP.OUTRO | translate"></p>
      </section>
    </mat-tab>

    <mat-tab [label]="T.F.PROCRASTINATION.REFRAME.TITLE | translate">
      <section class="tab-inner">
        <p>{{ T.F.PROCRASTINATION.REFRAME.INTRO | translate }}</p>

        <mat-form-field>
          <mat-label>{{ T.F.PROCRASTINATION.REFRAME.TL1 | translate }}</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ T.F.PROCRASTINATION.REFRAME.TL2 | translate }}</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ T.F.PROCRASTINATION.REFRAME.TL3 | translate }}</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>
      </section>
    </mat-tab>

    @if (taskService.currentTaskOrCurrentParent$ | async; as currentTask) {
      <mat-tab [label]="T.F.PROCRASTINATION.SPLIT_UP.TITLE | translate">
        <section class="tab-inner no-box">
          <p>{{ T.F.PROCRASTINATION.SPLIT_UP.INTRO | translate }}</p>
          <task [task]="currentTask"></task>
          <p
            [innerHTML]="T.F.PROCRASTINATION.SPLIT_UP.OUTRO | translate"
            style="margin-top: 16px"
          ></p>
        </section>
      </mat-tab>
    }
  </mat-tab-group>

  <div style="text-align: center; margin-top: 32px">
    <button
      color="primary"
      mat-flat-button
      (click)="backToWork()"
    >
      {{ T.F.PROCRASTINATION.BACK_TO_WORK | translate }}
    </button>
  </div>
</div>
