@if (tags()?.length > 0) {
  <div class="tags-container">
    <div class="tags">
      @for (tag of tags(); track tag.id) {
        <!-- -->
        <tag [tag]="tag"></tag>
      }
    </div>
  </div>
}
