@if (globalProgressBarService.label$ | async; as label) {
  <div
    [@fadeOut]
    class="badge"
  >
    {{ label | translate }}
  </div>
}
@if (globalProgressBarService.isShowGlobalProgressBar$ | async) {
  <div [@fadeInOutBottom]>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
}

<!--<div class="badge">Some test text</div>-->
<!--<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
