<collapsible
  [isExpanded]="isExpanded"
  [isIconBefore]="true"
  [title]="section?.title | translate"
>
  <!--[icon]="section?.icon"-->

  @if (section?.help || section?.helpArr) {
    <help-section>
      @if (section?.help) {
        <div [innerHtml]="section?.help | translate"></div>
      }
      @if (section?.helpArr) {
        @for (helpSection of section?.helpArr; track trackByIndex($index, helpSection)) {
          @if (helpSection.h) {
            <div class="mat-caption">{{ helpSection.h | translate }}</div>
          }
          @if (helpSection.p) {
            <p [innerHTML]="helpSection.p | translate"></p>
          }
          @if (helpSection.p2) {
            <p [innerHTML]="helpSection.p2 | translate"></p>
          }
          @if (helpSection.p3) {
            <p [innerHTML]="helpSection.p3 | translate"></p>
          }
          @if (helpSection.p4) {
            <p [innerHTML]="helpSection.p4 | translate"></p>
          }
        }
      }
    </help-section>
  }
  <div class="form-wrapper {{ section?.key }}">
    @if (section?.items && !section?.customSection) {
      <config-form
        (save)="onSave($event)"
        [cfg]="cfg"
        [formCfg]="section?.items"
        [sectionKey]="section?.key"
      ></config-form>
    }

    <ng-template
      #customForm
      class="custom-form"
    ></ng-template>
  </div>
</collapsible>
