<h1 mat-dialog-title>
  {{
    (isSingleDay ? T.F.WORKLOG.D_EXPORT_TITLE_SINGLE : T.F.WORKLOG.D_EXPORT_TITLE)
      | translate: { day: strStart, start: strStart, end: strEnd }
  }}
</h1>

<worklog-export
  (cancel)="close()"
  [isShowClose]="true"
  [isWorklogExport]="data.isWorklogExport"
  [projectId]="data.projectId"
  [rangeEnd]="data.rangeEnd"
  [rangeStart]="data.rangeStart"
></worklog-export>
