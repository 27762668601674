<h3>{{ T.PDS.TIME_SPENT_TODAY_BY_TAG | translate }}</h3>
<table>
  <tbody>
    @for (tagWithTimeSpent of tagsWithTimeSpent$ | async; track tagWithTimeSpent.id) {
      <tr>
        <td>
          <tag
            style="pointer-events: none"
            [tag]="tagWithTimeSpent.tag"
          ></tag>
        </td>
        <td>{{ tagWithTimeSpent.timeSpentToday | msToString }}</td>
      </tr>
    }
  </tbody>
</table>
