<section class="main">
  @if (workContextService.mainWorkContext$ | async; as mainContext) {
    <side-nav-item
      #menuEntry
      [type]="WorkContextType.TAG"
      [defaultIcon]="mainContext.icon"
      [workContext]="mainContext"
      [activeWorkContextId]="activeWorkContextId"
    ></side-nav-item>
  }

  <button
    #menuEntry
    class="route-link"
    mat-menu-item
    routerLink="schedule"
    routerLinkActive="isActiveRoute"
  >
    <mat-icon svgIcon="early_on"></mat-icon>
    <span class="text">{{ T.MH.SCHEDULE | translate }}</span>
  </button>

  <button
    #menuEntry
    class="route-link"
    mat-menu-item
    routerLink="planner"
    routerLinkActive="isActiveRoute"
  >
    <mat-icon>edit_calendar</mat-icon>
    <span class="text">{{ T.MH.PLANNER | translate }}</span>
  </button>

  <button
    #menuEntry
    class="route-link"
    mat-menu-item
    routerLink="boards"
    routerLinkActive="isActiveRoute"
  >
    <mat-icon>grid_view</mat-icon>
    <span class="text">{{ T.MH.BOARDS | translate }}</span>
  </button>
</section>

@if (nonHiddenProjects$ | async; as projectList) {
  <section class="projects tour-projects">
    <div class="g-multi-btn-wrapper">
      <button
        #menuEntry
        #projectExpandBtn
        (click)="toggleExpandProjects()"
        (keydown)="toggleExpandProjectsLeftRight($event)"
        [class.isExpanded]="isProjectsExpanded"
        class="expand-btn"
        mat-menu-item
      >
        <mat-icon>expand_more</mat-icon>
        <span class="title">{{ T.MH.PROJECTS | translate }}</span>
      </button>
      <!--    <button-->
      <!--      #projectVisibilityBtn-->
      <!--      mat-icon-button-->
      <!--      class="additional-btn"-->
      <!--    >-->
      <!--      <mat-icon>visibility</mat-icon>-->
      <!--    </button>-->
      <button
        [style.display]="projectList.length ? '' : 'none'"
        class="additional-btn"
        #projectExpandBtn2
        mat-icon-button
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <button
        (click)="addProject()"
        class="additional-btn"
        mat-icon-button
        [matTooltip]="T.MH.CREATE_PROJECT | translate"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <!--  [leftClickTriggerEl]="projectVisibilityBtn"-->
    <context-menu
      [contextMenu]="contextMenu"
      [leftClickTriggerEl]="projectExpandBtn2"
      [rightClickTriggerEl]="projectExpandBtn"
    ></context-menu>
    <ng-template #contextMenu>
      @for (project of allProjects$ | async; track project.id) {
        <button
          mat-menu-item
          (click)="toggleProjectVisibility(project)"
        >
          @if (!project.isHiddenFromMenu) {
            <mat-icon>visibility</mat-icon>
          }
          @if (project.isHiddenFromMenu) {
            <mat-icon>visibility_off</mat-icon>
          }
          {{ project.title }}
        </button>
      }
    </ng-template>
    <div
      [@standardList]="projectList?.length"
      cdkDropList
      (cdkDropListDropped)="dropOnProjectList(projectList, $event)"
    >
      @for (project of projectList; track project.id) {
        <side-nav-item
          #menuEntry
          cdkDrag
          [cdkDragData]="project"
          [cdkDragStartDelay]="IS_TOUCH_PRIMARY ? DRAG_DELAY_FOR_TOUCH_LONGER : 0"
          (keydown)="checkFocusProject($event)"
          [workContext]="project"
          [type]="WorkContextType.PROJECT"
          [defaultIcon]="'folder_special'"
          [activeWorkContextId]="activeWorkContextId"
        ></side-nav-item>
      }
    </div>
  </section>
}
@if (tagList$ | async; as tagList) {
  <section class="tags">
    <button
      #menuEntry
      #tagExpandBtn
      (click)="toggleExpandTags()"
      (keydown)="toggleExpandTagsLeftRight($event)"
      [class.isExpanded]="isTagsExpanded"
      class="expand-btn"
      mat-menu-item
    >
      <span class="title">{{ T.MH.TAGS | translate }}</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <div
      [@standardList]="tagList?.length"
      cdkDropList
      (cdkDropListDropped)="dropOnTagList(tagList, $event)"
    >
      @for (tag of tagList; track tag.id) {
        <side-nav-item
          #menuEntry
          cdkDrag
          [cdkDragData]="tag"
          [cdkDragStartDelay]="IS_TOUCH_PRIMARY ? DRAG_DELAY_FOR_TOUCH_LONGER : 0"
          (keydown)="checkFocusTag($event)"
          [workContext]="tag"
          [type]="WorkContextType.TAG"
          [defaultIcon]="'label'"
          [activeWorkContextId]="activeWorkContextId"
        ></side-nav-item>
      }
    </div>
    @if (!tagList.length && isTagsExpanded) {
      <div class="no-tags-info">{{ T.MH.NO_TAG_INFO | translate }}</div>
    }
    <!--  <button (click)="addTag()"-->
    <!--          *ngIf="isTagsExpanded"-->
    <!--   #menuEntry mat-menu-item>-->
    <!--    <mat-icon>add</mat-icon>-->
    <!--    {{T.MH.CREATE_TAG|translate}}-->
    <!--  </button>-->
  </section>
}

<!--<section class="other-task-level-section">-->
<!--</section>-->

<section class="app">
  <button
    #menuEntry
    class="route-link"
    mat-menu-item
    routerLink="scheduled-list"
    routerLinkActive="isActiveRoute"
  >
    <mat-icon svgIcon="repeat"></mat-icon>
    <span class="text">{{ T.MH.REPEAT_SCHEDULED | translate }}</span>
  </button>
  <button
    #menuEntry
    [mat-menu-trigger-for]="helpMenu"
    class="route-link"
    mat-menu-item
  >
    <mat-icon>help_center</mat-icon>
    <span class="text">{{ T.MH.HELP | translate }}</span>
  </button>

  <mat-menu #helpMenu="matMenu">
    <ng-template matMenuContent>
      <a
        mat-menu-item
        href="https://github.com/johannesjo/super-productivity/blob/master/README.md#question-how-to-use-it"
        target="_blank"
      >
        <mat-icon>help_center</mat-icon>
        <span class="text">{{ T.MH.HM.GET_HELP_ONLINE | translate }}</span>
      </a>

      <a
        class="route-link"
        mat-menu-item
        [href]="getGithubErrorUrl()"
        target="_blank"
      >
        <mat-icon>bug_report</mat-icon>
        <span class="text">{{ T.MH.HM.REPORT_A_PROBLEM | translate }}</span>
      </a>

      <a
        mat-menu-item
        href="https://github.com/johannesjo/super-productivity/blob/master/CONTRIBUTING.md"
        target="_blank"
      >
        <mat-icon>volunteer_activism</mat-icon>
        <span class="text">{{ T.MH.HM.CONTRIBUTE | translate }}</span>
      </a>

      <a
        mat-menu-item
        href="https://www.reddit.com/r/superProductivity/"
        target="_blank"
      >
        <mat-icon>forum</mat-icon>
        <span class="text">{{ T.MH.HM.REDDIT_COMMUNITY | translate }}</span>
      </a>

      <button
        (click)="startTour(TourId.Welcome)"
        mat-menu-item
      >
        <mat-icon>directions</mat-icon>
        <span class="text">{{ T.MH.HM.START_WELCOME | translate }}</span>
      </button>
      @if (IS_MOUSE_PRIMARY) {
        <button
          (click)="startTour(TourId.KeyboardNav)"
          mat-menu-item
        >
          <mat-icon>directions</mat-icon>
          <span class="text">{{ T.MH.HM.KEYBOARD | translate }}</span>
        </button>
      }
      <button
        (click)="startTour(TourId.Sync)"
        mat-menu-item
      >
        <mat-icon>directions</mat-icon>
        <span class="text">{{ T.MH.HM.SYNC | translate }}</span>
      </button>
      <button
        (click)="startTour(TourId.IssueProviders)"
        mat-menu-item
      >
        <mat-icon>directions</mat-icon>
        <span class="text">{{ T.MH.HM.CALENDARS | translate }}</span>
      </button>
    </ng-template>
  </mat-menu>

  <button
    #menuEntry
    class="route-link tour-settingsMenuBtn"
    mat-menu-item
    routerLink="config"
    routerLinkActive="isActiveRoute"
  >
    <mat-icon>settings</mat-icon>
    <span class="text">{{ T.MH.SETTINGS | translate }}</span>
  </button>
</section>

<!-- NOTE: needs to be here for mat menu styles always to be loaded -->
<mat-menu></mat-menu>
