<mat-dialog-content>
  <!--  <h1>XXX {{(task$|async)?.id}}</h1>-->
  @if (task$ | async; as task) {
    <task-detail-panel
      [task]="task"
      [isDialogMode]="true"
    ></task-detail-panel>
  }
</mat-dialog-content>

<!--<mat-dialog-actions align="center">-->
<!--  <button-->
<!--    mat-flat-button-->
<!--    color="primary"-->
<!--    mat-dialog-close-->
<!--  >-->
<!--    <mat-icon>close</mat-icon> {{T.G.CLOSE|translate}}-->
<!--  </button>-->
<!--</mat-dialog-actions>-->
