<div
  #tagMenuTriggerEl
  [matMenuTriggerFor]="menuEl"
  style="visibility: hidden"
></div>

<mat-menu
  #menuEl="matMenu"
  (closed)="afterClose.emit()"
>
  <ng-template matMenuContent>
    @for (tag of toggleTagList(); track $index) {
      <button
        (click)="toggleTag.emit(tag.id)"
        (keydown)="onTagMenuKeydown($event, tag.id)"
        mat-menu-item
      >
        @if (task().tagIds.includes(tag.id)) {
          <mat-icon>check_box</mat-icon>
        } @else {
          <mat-icon>check_box_outline_blank</mat-icon>
        }
        <mat-icon
          [style.color]="tag.color || tag.theme.primary"
          class="tag-ico"
          >{{ tag.icon || 'label' }}</mat-icon
        >
        {{ tag.title }}
      </button>
    }
    <button
      mat-menu-item
      (click)="openAddNewTag()"
    >
      <mat-icon>add</mat-icon>
      {{ T.F.TAG.TTL.ADD_NEW_TAG | translate }}
    </button>
  </ng-template>
</mat-menu>
