<h1 mat-dialog-title>
  {{ T.F.SYNC.D_AUTH_CODE.TITLE | translate: { provider: data.providerName } }}
</h1>

<mat-dialog-content>
  <p>{{ T.F.SYNC.D_AUTH_CODE.FOLLOW_LINK | translate }}</p>
  <a
    mat-button
    color="primary"
    target="_blank"
    mat-stroked-button
    [href]="data.url"
  >
    <mat-icon>open_in_new</mat-icon>
    {{ T.F.SYNC.D_AUTH_CODE.GET_AUTH_CODE | translate }}</a
  >
  <br />
  <br />
  <mat-form-field>
    <mat-label>{{ T.F.SYNC.D_AUTH_CODE.L_AUTH_CODE | translate }}</mat-label>
    <mat-icon matPrefix>vpn_key</mat-icon>
    <input
      matInput
      [(ngModel)]="token"
    />
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="wrap-buttons">
    <button
      (click)="close()"
      color="primary"
      mat-button
    >
      {{ T.G.CANCEL | translate }}
    </button>
    <button
      (click)="close(token)"
      [disabled]="!token"
      color="primary"
      mat-stroked-button
    >
      <mat-icon>save</mat-icon>
      {{ T.G.SAVE | translate }}
    </button>
  </div>
</mat-dialog-actions>
