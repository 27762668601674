<div class="wrapper">
  @if (ready$ | async) {
    <div class="task-list-wrapper">
      <task-list
        [isBacklog]="true"
        [noTasksMsg]="T.BL.NO_TASKS | translate"
        [tasks]="backlogTasks()"
        class="isHidePlayBtn"
        listId="PARENT"
        listModelId="BACKLOG"
      ></task-list>
    </div>
  } @else {
    <full-page-spinner></full-page-spinner>
  }
</div>
