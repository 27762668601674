<h1 mat-dialog-title>{{ T.F.SYNC.D_PERMISSION.TITLE | translate }}</h1>

<mat-dialog-content>
  <div [innerHTML]="T.F.SYNC.D_PERMISSION.TEXT | translate"></div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="wrap-buttons">
    <button
      (click)="grantPermission()"
      color="primary"
      mat-stroked-button
    >
      <mat-icon>perm_media</mat-icon>
      {{ T.F.SYNC.D_PERMISSION.PERM_FILE | translate }}
    </button>
    <button
      (click)="disableSync()"
      color="warn"
      mat-stroked-button
    >
      <mat-icon>sync_disabled</mat-icon>
      {{ T.F.SYNC.D_PERMISSION.DISABLE_SYNC | translate }}
    </button>
  </div>
</mat-dialog-actions>
