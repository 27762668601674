<collapsible
  [isExpanded]="false"
  [isIconBefore]="true"
  [title]="title | translate"
  [formGroup]="soundForm"
>
  <div class="form-wrapper">
    <mat-form-field
      class="width100"
      [hideRequiredMarker]="true"
    >
      <mat-label>{{ 'GCF.SOUND.VOLUME' | translate }}</mat-label>
      <mat-slider
        class="width100"
        [min]="0"
        [max]="100"
        [step]="1"
        [discrete]="true"
      >
        <input
          style="height: 100%"
          matInput
          matSliderThumb
          formControlName="volume"
        />
      </mat-slider>
    </mat-form-field>
    <mat-form-field class="width100">
      <mat-label>{{ 'GCF.SOUND.DONE_SOUND' | translate }}</mat-label>
      <mat-select formControlName="doneSound">
        @for (sound of soundOpts; track sound) {
          <mat-option [value]="sound.value">{{ sound.label }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width100">
      <input
        hidden="true"
        matInput
      />
      <mat-slide-toggle
        matInput
        [checked]="this.config?.isIncreaseDoneSoundPitch"
        formControlName="isIncreaseDoneSoundPitch"
        >{{ 'GCF.SOUND.IS_INCREASE_DONE_PITCH' | translate }}</mat-slide-toggle
      >
    </mat-form-field>
    <mat-form-field class="width100">
      <mat-label>{{ 'GCF.SOUND.BREAK_REMINDER_SOUND' | translate }}</mat-label>
      <mat-select formControlName="breakReminderSound">
        @for (sound of soundOpts; track sound) {
          <mat-option [value]="sound.value">{{ sound.label }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</collapsible>
