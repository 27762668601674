@if (!(workContextService.isContextChangingWithDelay$ | async)) {
  <right-panel @projectChange>
    <div
      #containerEl
      class="wrapper"
      cdkDropListGroup
    >
      <div
        #splitTopEl
        class="today"
        cdkScrollable
      >
        <header class="work-view-header">
          @if (!isPlanningMode() && (estimateRemainingToday() || workingToday())) {
            <div
              @fade
              class="status-bar"
            >
              <div
                class="item"
                matTooltip="{{ T.WW.TODAY_REMAINING | translate }} ~{{
                  todayRemainingInProject() | msToString
                }}"
                [matTooltipDisabled]="
                  !(workContextService.isActiveWorkContextProject$ | async)
                "
                matTooltipPosition="above"
              >
                <span class="label">{{ T.WW.ESTIMATE_REMAINING | translate }}</span>
                <span class="no-wrap">
                  ~<strong class="time-val">{{
                    estimateRemainingToday() | msToString
                  }}</strong>
                  <mat-icon
                    style="margin-left: 2px"
                    svgIcon="estimate_remaining"
                  ></mat-icon>
                </span>
              </div>
              <div class="item">
                <span class="label">{{ T.WW.WORKING_TODAY | translate }}</span>
                <span class="no-wrap">
                  <strong class="time-val">{{ workingToday() | msToString }}</strong>
                  @if (
                    workContextService.workingTodayArchived$ | async;
                    as workingTodayArchived
                  ) {
                    <span [matTooltip]="T.WW.WORKING_TODAY_ARCHIVED | translate"
                      >(+{{ workingTodayArchived | msToString }})</span
                    >
                  }
                  <mat-icon svgIcon="working_today"></mat-icon>
                </span>
              </div>
              @if (isShowTimeWorkedWithoutBreak) {
                <div class="item hide-xs">
                  <span class="label">{{ T.WW.WITHOUT_BREAK | translate }} </span>
                  <span class="no-wrap">
                    <strong class="time-val">{{
                      takeABreakService.timeWorkingWithoutABreak$ | async | msToString
                    }}</strong
                    ><mat-icon>timer</mat-icon>
                  </span>
                  <div
                    [matTooltip]="T.WW.RESET_BREAK_TIMER | translate"
                    class="take-a-break-reset-btn"
                  >
                    <button
                      (click)="resetBreakTimer()"
                      color="lighter"
                      mat-mini-fab
                    >
                      <mat-icon>timer_off</mat-icon>
                    </button>
                  </div>
                </div>
              }
            </div>
          }
          @if (isPlanningMode()) {
            <div class="planning-mode-header">
              @if (!undoneTasks()?.length) {
                <h3 class="planning-mode-header mat-h3">
                  {{ T.WW.ADD_SOME_TASKS | translate }}
                </h3>
              }
              @if (undoneTasks()?.length) {
                <div class="status-bar">
                  <div class="item">
                    <span class="label">{{ T.WW.TIME_ESTIMATED | translate }}</span>
                    <span class="no-wrap">
                      <strong class="time-val"
                        >~{{
                          workContextService.estimateRemainingToday$ | async | msToString
                        }}</strong
                      >
                      <mat-icon>timer</mat-icon>
                    </span>
                  </div>
                </div>
              }
            </div>
          }
        </header>
        @if (improvementService.hasLastTrackedImprovements$ | async) {
          <improvement-banner [@expandFade]></improvement-banner>
        }
        <div class="task-list-wrapper">
          @if (!isPlanningMode() && !(workContextService.isHasTasksToWorkOn$ | async)) {
            <div
              @expandFade
              class="add-more-or-finish"
            >
              <h2>{{ T.WW.NO_PLANNED_TASKS | translate }}</h2>
              <div class="btn-wrapper">
                <button
                  (click)="planMore()"
                  color=""
                  mat-flat-button
                  tabindex="1"
                >
                  <mat-icon>playlist_add</mat-icon>
                  {{ T.WW.ADD_MORE | translate }}
                </button>
                @if (workContextService.isToday$ | async) {
                  <button
                    [matTooltip]="T.WW.FINISH_DAY_TOOLTIP | translate"
                    color="primary"
                    mat-flat-button
                    routerLink="/active/daily-summary"
                    tabindex="1"
                  >
                    <mat-icon>done_all</mat-icon>
                    {{ T.WW.FINISH_DAY | translate }}
                  </button>
                }
                @if (
                  !(workContextService.isToday$ | async) &&
                  workContextService.activeWorkContextType === 'PROJECT'
                ) {
                  <button
                    [matTooltip]="T.WW.FINISH_DAY_TOOLTIP | translate"
                    color="primary"
                    mat-flat-button
                    routerLink="/active/daily-summary"
                    tabindex="1"
                  >
                    <mat-icon>done_all</mat-icon>
                    {{ T.WW.FINISH_DAY_FOR_PROJECT | translate }}
                  </button>
                }
                @if (
                  !(workContextService.isToday$ | async) &&
                  workContextService.activeWorkContextType === 'TAG'
                ) {
                  <button
                    [matTooltip]="T.WW.FINISH_DAY_TOOLTIP | translate"
                    color="primary"
                    mat-flat-button
                    routerLink="/active/daily-summary"
                    tabindex="1"
                  >
                    <mat-icon>done_all</mat-icon>
                    {{ T.WW.FINISH_DAY_FOR_TAG | translate }}
                  </button>
                }
              </div>
            </div>
          }
          @if (isPlanningMode()) {
            <div
              @expandFade
              class="planning-mode-content"
            >
              <add-task-bar
                (done)="startWork()"
                [tabindex]="1"
                [isDoubleEnterMode]="true"
              ></add-task-bar>
              <add-scheduled-today-or-tomorrow-btn
                [isAlwaysShowIfAny]="true"
              ></add-scheduled-today-or-tomorrow-btn>
              <button
                (click)="startWork()"
                class="ready-to-work-btn"
                color="primary"
                mat-flat-button
                tabindex="1"
              >
                <mat-icon>check</mat-icon>
                {{ T.WW.READY_TO_WORK | translate }}
              </button>
            </div>
          }
          <task-list
            class="tour-undoneList"
            [tasks]="undoneTasks()"
            listId="PARENT"
            listModelId="UNDONE"
          ></task-list>
          @if (!isPlanningMode() || doneTasks()?.length) {
            <div @expand>
              <h2 class="mat-h2 completed-tasks-heading">
                {{ T.WW.COMPLETED_TASKS | translate }}
              </h2>
              <task-list
                class="tour-doneList"
                [noTasksMsg]="T.WW.NO_COMPLETED_TASKS | translate"
                [tasks]="doneTasks()"
                listId="PARENT"
                listModelId="DONE"
              ></task-list>
              <div class="finish-day-button-wrapper">
                <!-- NOTE: tabindex is same as for tasks -->
                @if (workContextService.isToday$ | async) {
                  <button
                    [matTooltip]="T.WW.FINISH_DAY_TOOLTIP | translate"
                    color="primary"
                    mat-flat-button
                    routerLink="/active/daily-summary"
                    tabindex="1"
                  >
                    <mat-icon>done_all</mat-icon>
                    {{ T.WW.FINISH_DAY | translate }}
                  </button>
                }
                @if (
                  !(workContextService.isToday$ | async) &&
                  workContextService.activeWorkContextType === 'PROJECT'
                ) {
                  <button
                    [matTooltip]="T.WW.FINISH_DAY_TOOLTIP | translate"
                    color="primary"
                    mat-flat-button
                    routerLink="/active/daily-summary"
                    tabindex="1"
                  >
                    <mat-icon>done_all</mat-icon>
                    {{ T.WW.FINISH_DAY_FOR_PROJECT | translate }}
                  </button>
                }
                @if (
                  !(workContextService.isToday$ | async) &&
                  workContextService.activeWorkContextType === 'TAG'
                ) {
                  <button
                    [matTooltip]="T.WW.FINISH_DAY_TOOLTIP | translate"
                    color="primary"
                    mat-flat-button
                    routerLink="/active/daily-summary"
                    tabindex="1"
                  >
                    <mat-icon>done_all</mat-icon>
                    {{ T.WW.FINISH_DAY_FOR_TAG | translate }}
                  </button>
                }
              </div>
            </div>
          }
        </div>
      </div>
      @if (isShowBacklog()) {
        <div
          #splitBottomEl
          [style.user-focus]="splitInputPos === 100 ? 'none' : ''"
          class="backlog"
        >
          <split
            (posChanged)="splitInputPos = $event"
            [containerEl]="containerEl"
            [counter]="backlogTasks()?.length"
            [isAnimateBtn]="isTriggerBacklogIconAni$ | async"
            [splitBottomEl]="splitBottomEl"
            [splitPos]="splitInputPos"
            [splitTopEl]="splitTopEl"
          ></split>
          @if (splitInputPos !== 100) {
            <div
              class="inner-wrapper"
              style="user-select: none"
            >
              <backlog
                (closeBacklog)="splitInputPos = 100"
                [backlogTasks]="backlogTasks()"
              ></backlog>
            </div>
          }
        </div>
      }
    </div>
  </right-panel>
}

<button
  (click)="layoutService.showAddTaskBar()"
  [class.isRight]="isShowBacklog()"
  [matTooltip]="T.MH.ADD_NEW_TASK | translate"
  class="show-xs-only add-task-btn FAB-BTN"
  color="primary"
  mat-fab
>
  <mat-icon>add</mat-icon>
</button>
