<div
  class="dialog-content"
  mat-dialog-content
>
  <textarea
    [(ngModel)]="txt"
    [hidden]="!isShowAsText"
    class="simple-textarea mat-elevation-z2"
    id="task-textarea"
    name="tasksTxt"
    rows="10"
    tabindex="-1"
  ></textarea>

  @if (!isShowAsText) {
    <div
      [class.isNoCols]="!options.cols?.length"
      class="wrapper-wrapper"
    >
      <button
        [matMenuTriggerFor]="addColMenu"
        [matTooltip]="T.F.WORKLOG.EXPORT.ADD_COL | translate"
        class="add-col"
        color=""
        mat-mini-fab
        matTooltipPosition="left"
      >
        <mat-icon>add</mat-icon>
      </button>
      <mat-menu #addColMenu="matMenu">
        @for (colOpt of colOpts; track $index) {
          <button
            (click)="addCol(colOpt.id)"
            mat-menu-item
          >
            {{ colOpt.title | translate }}
          </button>
        }
      </mat-menu>
      <div class="table-wrapper material-table _no-elevation">
        <table>
          <tr class="control-row">
            @for (col of options.cols; track i; let i = $index) {
              <td>
                <mat-select
                  (selectionChange)="onOptionsChange()"
                  [(ngModel)]="options.cols[i]"
                  [matTooltip]="T.F.WORKLOG.EXPORT.EDIT_COL | translate"
                  matTooltipPosition="above"
                >
                  <mat-option><em>remove</em></mat-option>
                  @for (colOpt of colOpts; track colOpt) {
                    <mat-option [value]="colOpt.id">
                      {{ colOpt.title | translate }}
                    </mat-option>
                  }
                </mat-select>
              </td>
            }
          </tr>
          <tr>
            @for (headlineCol of headlineCols; track i; let i = $index) {
              <th [ngClass]="options.cols[i]">{{ headlineCol }}</th>
            }
          </tr>
          @for (row of formattedRows; track row) {
            <tr>
              @for (col of row; track col; let i = $index) {
                <td
                  [innerHTML]="col"
                  [ngClass]="options.cols[i]"
                ></td>
              }
            </tr>
          }
        </table>
      </div>
    </div>
  }

  <collapsible
    [isInline]="true"
    [title]="T.F.WORKLOG.EXPORT.OPTIONS | translate"
    class="options-collapsible"
  >
    <section class="options">
      <div class="row">
        <div class="col">
          <div>
            <mat-slide-toggle
              (change)="onOptionsChange()"
              [(ngModel)]="isShowAsText"
              name="isShowAsText"
            >
              {{ T.F.WORKLOG.EXPORT.SHOW_AS_TEXT | translate }}
            </mat-slide-toggle>
          </div>
        </div>
        <div class="col">
          @if (isWorklogExport()) {
            <mat-form-field class="md-block">
              <label>{{ T.F.WORKLOG.EXPORT.SEPARATE_TASKS_BY | translate }}</label>
              <input
                (change)="onOptionsChange()"
                [(ngModel)]="options.separateTasksBy"
                matInput
                name="separateTasksBy"
                type="text"
              />
            </mat-form-field>
          }
        </div>
        <div class="col">
          <mat-form-field>
            <label>{{ T.F.WORKLOG.EXPORT.ROUND_TIME_WORKED_TO | translate }}</label>
            <mat-select
              (selectionChange)="onOptionsChange()"
              [(ngModel)]="options.roundWorkTimeTo"
            >
              <mat-option
                ><em>{{ T.F.WORKLOG.EXPORT.DONT_ROUND | translate }}</em></mat-option
              >
              @for (roundOption of roundTimeOptions; track $index) {
                <mat-option [value]="roundOption.id">
                  {{ roundOption.title | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <label>{{ T.F.WORKLOG.EXPORT.ROUND_START_TIME_TO | translate }}</label>
            <mat-select
              (selectionChange)="onOptionsChange()"
              [(ngModel)]="options.roundStartTimeTo"
            >
              <mat-option
                ><em>{{ T.F.WORKLOG.EXPORT.DONT_ROUND | translate }}</em></mat-option
              >
              @for (roundOption of roundTimeOptions; track $index) {
                <mat-option [value]="roundOption.id">
                  {{ roundOption.title | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <label>{{ T.F.WORKLOG.EXPORT.ROUND_END_TIME_TO | translate }}</label>
            <mat-select
              (selectionChange)="onOptionsChange()"
              [(ngModel)]="options.roundEndTimeTo"
            >
              <mat-option
                ><em>{{ T.F.WORKLOG.EXPORT.DONT_ROUND | translate }}</em></mat-option
              >
              @for (roundOption of roundTimeOptions; track $index) {
                <mat-option [value]="roundOption.id">
                  {{ roundOption.title | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <label>{{ T.F.WORKLOG.EXPORT.GROUP_BY | translate }}</label>
            <mat-select
              (selectionChange)="onOptionsChange()"
              [(ngModel)]="options.groupBy"
            >
              @for (groupByOption of groupByOptions; track $index) {
                <mat-option [value]="groupByOption.id">
                  {{ groupByOption.title | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </section>
  </collapsible>
</div>

<div
  align="end"
  mat-dialog-actions
>
  @if (isShowAsText) {
    <button
      color="primary"
      data-clipboard-action="copy"
      data-clipboard-target="#task-textarea"
      id="clipboard-btn"
      mat-button
    >
      <mat-icon>content_paste</mat-icon>
      {{ T.F.WORKLOG.EXPORT.COPY_TO_CLIPBOARD | translate }}
    </button>
  }

  <a
    [simpleDownloadData]="txt"
    [simpleDownload]="fileName"
    color="primary"
    mat-button
  >
    <mat-icon>file_download</mat-icon>
    {{ T.F.WORKLOG.EXPORT.SAVE_TO_FILE | translate }}
  </a>

  @if (isShowClose()) {
    <button
      (click)="onCloseClick()"
      color="primary"
      mat-stroked-button
      type="button"
    >
      {{ T.G.CLOSE | translate }}
    </button>
  }
</div>
