<mat-form-field>
  <mat-label>{{ T.F.TASK.SELECT_OR_CREATE | translate }}</mat-label>
  <input
    [formControl]="taskSelectCtrl"
    [matAutocomplete]="auto"
    cdkFocusInitial
    autofocus="autofocus"
    matInput
    required
  />
  @if (!isCreate) {
    <mat-icon matSuffix="">track_changes</mat-icon>
  }
  @if (isCreate) {
    <mat-icon matSuffix="">add</mat-icon>
  }

  <mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
    [displayWith]="displayWith"
  >
    @for (task of filteredTasks; track trackById($index, task)) {
      <mat-option
        [value]="task"
        [class.isDone]="task.isDone"
      >
        @if (task.issueType) {
          <mat-icon [svgIcon]="task.issueType | issueIcon"></mat-icon>
        }
        <!---->
        @if (task.projectId) {
          <!---->
          @if (!task.issueType && !projectMap[task.projectId]) {
            <mat-icon>library_books</mat-icon>
          }
          @if (projectMap[task.projectId]) {
            <tag [tag]="projectMap[task.projectId]"></tag>
          }
          <!---->
        }
        <span [innerHTML]="task.title"></span>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
