<div class="dialog-help-wrapper">
  <h1 mat-dialog-title>{{ T.F.TASK.D_TIME_FOR_DAY.TITLE | translate }}</h1>

  <form
    (submit)="$event.preventDefault(); submit()"
    name="addForAnotherDayForm"
  >
    <mat-dialog-content>
      <help-section>
        <div
          [innerHTML]="T.F.TASK.D_TIME_FOR_DAY.HELP | translate"
          class="side-info"
        ></div>
      </help-section>

      <h3 class="mat-caption">
        {{
          T.F.TASK.D_TIME_FOR_DAY.ADD_ENTRY_FOR
            | translate: { date: (newEntry.date | date: 'dd-MM-yyyy') || '' }
        }}
      </h3>
      <mat-form-field>
        <mat-label>{{ T.F.TASK.D_TIME_FOR_DAY.DATE | translate }}</mat-label>
        <input
          [(ngModel)]="newEntry.date"
          [matDatepicker]="myDatepicker"
          matInput
          name="date"
          required
          type="text"
        />
        <mat-error>{{ T.V.E_DATETIME | translate }}</mat-error>
        <mat-datepicker-toggle
          [for]="myDatepicker"
          matPrefix
        ></mat-datepicker-toggle>
        <mat-datepicker #myDatepicker></mat-datepicker>
      </mat-form-field>

      <div class="wrap-time">
        <input-duration-slider
          [(model)]="newEntry.timeSpent"
          [label]="T.F.TASK.D_TIME_FOR_DAY.TINE_SPENT | translate"
        >
        </input-duration-slider>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button
        [mat-dialog-close]="true"
        color="primary"
        mat-button
        type="button"
      >
        {{ T.G.CANCEL | translate }}
      </button>

      <button
        color="primary"
        mat-stroked-button
        type="submit"
      >
        <mat-icon>save</mat-icon>
        {{ T.G.SAVE | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
