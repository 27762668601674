@if (task()?.issueWasUpdated) {
  <mat-icon
    color="accent"
    style="margin-right: 8px"
    >update
  </mat-icon>
}
@if (!task()?.issueWasUpdated) {
  <mat-icon
    style="margin-right: 8px"
    svgIcon="caldav"
  >
  </mat-icon>
}
<span class="tab-label-text">Caldav</span>
