<form
  #form="ngForm"
  (submit)="$event.preventDefault(); submit()"
>
  <h1 mat-dialog-title>
    {{
      (attachmentCopy.id
        ? T.F.ATTACHMENT.DIALOG_EDIT.EDIT_ATTACHMENT
        : T.F.ATTACHMENT.DIALOG_EDIT.ADD_ATTACHMENT
      ) | translate
    }}
  </h1>

  <mat-dialog-content>
    <div class="form-wrapper">
      <mat-form-field>
        <mat-label>{{ mapTypeToLabel(attachmentCopy.type) | translate }}</mat-label>
        <input
          [(ngModel)]="attachmentCopy.path"
          matInput
          name="path"
          required
          type="text"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ T.G.TITLE | translate }}</mat-label>
        <input
          [(ngModel)]="attachmentCopy.title"
          autofocus="autofocus"
          matInput
          name="title"
          type="text"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ T.F.ATTACHMENT.DIALOG_EDIT.SELECT_TYPE | translate }}</mat-label>
        <mat-select
          [(ngModel)]="attachmentCopy.type"
          name="type"
          required="true"
        >
          @for (type of types; track trackByIndex($index, type)) {
            <mat-option [value]="type.type">{{ type.title | translate }} </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      (click)="close()"
      color="primary"
      mat-button
      type="button"
    >
      {{ T.G.CANCEL | translate }}
    </button>

    <button
      [disabled]="!form.valid"
      color="primary"
      mat-stroked-button
      type="submit"
    >
      <mat-icon>save</mat-icon>
      {{ T.G.SAVE | translate }}
    </button>
  </mat-dialog-actions>
</form>
