<div class="pyro">
  <div class="pyro-before"></div>
  <div class="pyro-after"></div>
</div>

<h1>{{ T.F.FOCUS_MODE.SESSION_COMPLETED | translate }}</h1>

<div class="msg">
  <div class="worked-for-label">{{ T.F.FOCUS_MODE.WORKED_FOR | translate }}</div>
  <div class="worked-for-value">
    {{ lastSessionTotalDuration$ | async | msToString: true }}
  </div>

  <div class="task-title-label">{{ T.F.FOCUS_MODE.ON | translate }}</div>
  <div class="task-title">{{ taskTitle$ | async }}</div>
</div>

<div class="btn-wrapper">
  <button
    mat-stroked-button
    color="primary"
    (click)="cancelAndCloseFocusOverlay()"
  >
    {{ T.F.FOCUS_MODE.BACK_TO_PLANNING | translate }}
  </button>

  @if (!(currentTask$ | async)) {
    <button
      mat-raised-button
      color="primary"
      (click)="startNextFocusSession()"
    >
      {{ T.F.FOCUS_MODE.START_NEXT_FOCUS_SESSION | translate }}
    </button>
  }
  @if (currentTask$ | async) {
    <button
      mat-raised-button
      color="primary"
      (click)="continueWithFocusSession()"
    >
      {{ T.F.FOCUS_MODE.CONTINUE_FOCUS_SESSION | translate }}
    </button>
  }
</div>
