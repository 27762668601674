<div class="mac-os-drag-bar"></div>

@if (!(isPomodoroEnabled$ | async)) {
  <header>
    <banner></banner>
  </header>
  @if (activePage()) {
    <main>
      @if (activePage() === FocusModePage.ProcrastinationHelp) {
        <button
          class="close-btn"
          mat-icon-button
          (click)="leaveProcrastinationHelp()"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
      @if (activePage() !== FocusModePage.ProcrastinationHelp) {
        <button
          class="close-btn"
          mat-icon-button
          (click)="closeOverlay()"
        >
          <mat-icon>close</mat-icon>
        </button>
      }

      <ng-template #selectModeTpl>
        <mat-button-toggle-group
          class="focus-mode-select-mode"
          [value]="selectedMode()"
          (change)="selectMode($event.value)"
        >
          <mat-button-toggle [value]="FocusModeMode.Flowtime">{{
            T.F.FOCUS_MODE.FLOWTIME | translate
          }}</mat-button-toggle>
          <!--      <mat-button-toggle [value]="FocusModeMode.Pomodoro">Pomodoro</mat-button-toggle>-->
          <mat-button-toggle [value]="FocusModeMode.Countdown">{{
            T.F.FOCUS_MODE.COUNTDOWN | translate
          }}</mat-button-toggle>
        </mat-button-toggle-group>
      </ng-template>

      <!-- -->
      @switch (activePage()) {
        <!-- -->
        @case (FocusModePage.ProcrastinationHelp) {
          <div
            class="scroll-container"
            @warpIn
          >
            <procrastination></procrastination>
          </div>
        }
        @case (FocusModePage.TaskSelection) {
          <ng-container *ngTemplateOutlet="selectModeTpl"></ng-container>
          <focus-mode-task-selection
            style="margin-bottom: auto; margin-top: -32px"
            @warpIn
          ></focus-mode-task-selection>
        }
        @case (FocusModePage.DurationSelection) {
          <ng-container *ngTemplateOutlet="selectModeTpl"></ng-container>
          <focus-mode-duration-selection
            style="margin-bottom: auto; margin-top: -32px"
            @warpIn
          ></focus-mode-duration-selection>
        }
        @case (FocusModePage.Preparation) {
          <focus-mode-preparation @warpIn></focus-mode-preparation>
        }
        @case (FocusModePage.Main) {
          <focus-mode-main @warpIn></focus-mode-main>
        }
        @case (FocusModePage.SessionDone) {
          <focus-mode-task-done @warpIn></focus-mode-task-done>
        }
      }
    </main>
  }
} @else {
  <button
    class="close-btn"
    mat-icon-button
    (click)="closeOverlay()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="pomodoro-info-wrapper">
    <div class="pomodoro-info-msg">{{ T.F.FOCUS_MODE.POMODORO_INFO | translate }}</div>
    <div style="text-align: center">
      <button
        mat-stroked-button
        color="primary"
        (click)="deactivatePomodoro()"
      >
        <mat-icon>alarm_off</mat-icon>
        {{ T.F.FOCUS_MODE.POMODORO_DISABLE | translate }}
      </button>
      <button
        mat-stroked-button
        (click)="cancelFocusSession()"
      >
        <mat-icon>arrow_back</mat-icon>
        {{ T.F.FOCUS_MODE.BACK_TO_PLANNING | translate }}
      </button>
    </div>
  </div>
}
