<h1 mat-dialog-title>
  <mat-icon>calendar_view_week</mat-icon>
  <span style="margin-left: 8px">{{ T.F.SCHEDULE.D_INITIAL.TITLE | translate }}</span>
</h1>

<form
  (submit)="$event.preventDefault(); saveAndClose()"
  [formGroup]="formGroup"
>
  <mat-dialog-content>
    @if (data?.isInfoShownInitially) {
      <div
        class="text"
        [innerHTML]="T.F.SCHEDULE.D_INITIAL.TEXT | translate"
      ></div>
    }
    @if (!data?.isInfoShownInitially) {
      <help-section>
        <div
          [innerHTML]="T.F.SCHEDULE.D_INITIAL.TEXT | translate"
          class="side-info"
        ></div>
      </help-section>
    }

    <formly-form
      (modelChange)="timelineCfg = $event"
      [fields]="formConfig"
      [form]="formGroup"
      [model]="timelineCfg"
    >
    </formly-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      (click)="close()"
      color="primary"
      mat-button
      type="button"
    >
      {{ T.G.CANCEL | translate }}
    </button>

    <button
      [disabled]="!formGroup.valid"
      color="primary"
      mat-stroked-button
      type="submit"
    >
      <mat-icon>save</mat-icon>
      {{ T.G.SAVE | translate }}
    </button>
  </mat-dialog-actions>
</form>
